// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const CleanVesselActPage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      cleanVesselActLogo: file(
        relativePath: { eq: "clean-vessel-act-logo.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  // COMPONENT
  return (
    <Layout title="Clean Vessel Act">
      <PageTitle title="CLEAN VESSEL ACT" />
      <ImageWithCaption
        fluid={data.cleanVesselActLogo.childImageSharp.fluid}
        caption="Pump out boat sticker"
        maxWidth={400}
      />
      <Typography paragraph style={{ marginTop: "4rem" }}>
        Please <Link to="/contact">contact us</Link> for help obtaining{" "}
        <acronym title="Clean Vessel Act">CVA</acronym> funding.
      </Typography>
      <Typography paragraph>
        The <Link to="/alcar-environmental">Alcar Environmental</Link> pumpout
        boat meets CVA program requirements.
      </Typography>
      <Typography paragraph>
        The primary goal of the Clean Vessel Act (CVA) is to reduce overboard
        sewage discharge from boats by providing pumpout and dump stations for
        boaters to dispose of human waste in an environmentally safe manner. The
        CVA provides funds to states for the construction, renovation, operation
        and maintenance of pumpout and dump stations for pumping out waste from
        recreational boat holding tanks and emptying portable toilets.
      </Typography>
      <Typography paragraph>
        In 1992 Congress passed the CVA to help reduce pollution from vessel
        sewage discharges. The CVA established a five-year federal grant program
        administered by the U.S. Fish and Wildlife Service and authorized $40
        million from the Sport Fish Restoration Account of the Aquatic Resources
        Trust Fund for use by the States. These federal funds can be used to
        account for up to 75 percent of all approved projects with the remaining
        funds supplemented by state or local government, private businesses or
        associations.
      </Typography>
      <Typography paragraph>
        In 1998, Congress appropriated $50 million to continue the pumpout grant
        program. The funding support was to expand the program to more areas
        throughout the United States and to make pumpouts more accessible to the
        public. Greater accessibility to pumpout and dump stations translates to
        less pollution in our waters. Pumpout funding continues to be awarded
        yearly to states through the Clean Vessel Act.
      </Typography>
    </Layout>
  )
}

export default CleanVesselActPage
